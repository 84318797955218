/**
 * Hex.js
 *
 * A library for manipulating hex color strings
 *
 * by Rafi Miller
 * 2018-04-18
 */
(function initHex() {
  window.Hex = {
    isValid,
    isShort,
    getLong,
    getShort,
    toRGB
  };

  // Regular expressions for testing
  const isValidRegExp = RegExp('^#(?:[0-9a-f]{3}){1,2}$', 'i');
  const isShortRegExp = RegExp('^#[0-9a-f]{3}$', 'i');

  /**
   * Tests if a value is valid hex color string of 3 or 6 characters.
   * If yes, then returns the original string.
   *
   * @param {*} val - a value to test
   * @return {string|boolean} - the original string if true, false if false
   */
  function isValid(val) {
    return typeof val === 'string' && isValidRegExp.test(val)
      ? val
      : false;
  }

  /**
   * Tests if a string is 3-character hex color.
   * If yes, then returns the original string.
   *
   * @param {string} hex - a hex color to test
   * @return {string|boolean} - the original string or false
   */
  function isShort(hex) {
    return isShortRegExp.test(hex) ? hex : false;
  }

  /**
   * Returns a 6-character hex color from any hex color.
   *
   * @param {string} hex - any hex color
   * @return {string} - a 6-character hex color
   */
  function getLong(hex) {
    return hex.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r + r + g + g + b + b}`
    );
  }

  /**
   * Returns a 3-character hex color from any hex color.
   * If there is no equivalent 3-character hex color, returns false.
   *
   * @param {string} hex - any hex color
   * @return {string|boolean} - a 3-character hex color or false
   */
  function getShort(hex) {
    // if it is already 3 characters, just return it
    if (isShort(hex)) {
      return hex;
    }

    // build a string with one character for each character that is doubled
    const shortHex = hex
      .toLowerCase()
      .split('')
      .reduce(
        (a, c, i) =>
          i > 0 && i % 2 === 0 && hex[i] === hex[i - 1] ? a + c : a,
        '#'
      );

    // the hex was shortenable if and only if the new string has 4 characters
    return shortHex.length === 4 ? shortHex : false;
  }

  /**
   *  Returns a length-3 array of RGB values from any hex color
   *
   * @param {string} hex - any hex color
   * @return {array} - array of [R, G, B]
   */
  function toRGB(hex) {
    return getLong(hex)
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16));
  }
})();
